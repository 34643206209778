import React from 'react';
import styles from './Campus.module.css';
import Header from './Header';
import { FaTiktok, FaInstagram, FaYoutube } from 'react-icons/fa';

export default function Campus() {
  return (
    <div className={styles.campus}>
      <Header />
      {/* Sección principal */}
      <section className={styles.hero}>
        <img
          src="https://i.ibb.co/jJK3hvf/TORCHAIN-LABORATORIO-1-ARREGLADO.jpg"
          alt="Ilustración de un ecosistema de aprendizaje virtual con personas y un robot en un entorno forestal"
          className={styles.heroImage}
        />
        <div className={styles.heroContent}>
          <div className={styles.brand}>
            <img
              src="https://i.ibb.co/kcLf9x2/marca-bootcamp-blanco.png"
              alt="TORCHAIN"
              className={styles.brandLogo}
            />
          </div>
          <h1 className={styles.heroTitle}>
            El Campus Para<br />
            La Familia Torchain
          </h1>
          <p className={styles.heroText}>
            Nuestro objetivo es ser la red profesional más grande del mundo enfocada en crear,
            multiplicar y preservar oportunidades para todas las familias.
          </p>
        </div>
      </section>

      {/* Sección inicial */}
      <section className={styles.startSection}>
        <div className={styles.startContainer}>
          <div className={styles.imageContainer}>
            <img
              src="https://i.ibb.co/SPjVntF/MAQUINA-HOMBRE.png"
              alt="Descripción de la imagen"
              className={styles.startImage}
            />
          </div>
          <div className={styles.textContainer}>
            <h2 className={styles.title}>EMPEZAR</h2>
            <h3 className={styles.subtitle}>Logra una reinvención profesional personalizada.</h3>
            <p className={styles.description}>
              Aunque nuestros profesionales son muy valiosos, queremos más miembros que desempeñen un papel clave en el futuro de la educación familiar en los próximos años.
            </p>
            {/* Enlace al formulario */}
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLScx7We-Vn-qbILta2zoIFUDYGGyF0x8APTPdopP7dj7l79egA/viewform?usp=dialog" // Cambia esta URL por la del formulario
              target="_blank" // Abre en una nueva pestaña
              rel="noopener noreferrer" // Mejora de seguridad
              className={styles.applyButtonEmp}
            >
              Aplicar Ahora
            </a>
          </div>
        </div>
      </section>
      {/* Sección: ¿Cómo puedo participar? */}
      <section className={styles.newSection}>
        <div className={styles.textContainer}>
          <p className={styles.subtitleSup}>¿CÓMO PUEDO PARTICIPAR?</p>
          <h2 className={styles.titleSup}>
            Entra en un nuevo ecosistema <br /> para <span className={styles.highlight}>reinventar tu profesión</span>
          </h2>
          <div className={styles.fullWidthDescription}>
            <p>
              Hemos desarrollados un ecosistema para que todos los miembros de Torchain logren ayudar a desarrollar el
              futuro de la educación, contribuyendo en el proyecto, aprendiendo y creciendo junto a un equipo de profesionales.
            </p>
          </div>
        </div>

        {/* Tarjetas */}
        <div className={styles.cardsContainer}>
          <div className={styles.card}>
            <img
              src="https://i.ibb.co/PFJ8p6g/EXPERTOS.png"
              alt="Aprende y forma parte de la familia"
              className={styles.cardImage}
            />
            <div className={styles.cardText}>
              <h3>Aprende y forma parte de la familia</h3>
              <p>
                La hoja de ruta de Torchain consta de actualizaciones interconectadas diseñadas para hacer el trabajo
                más escalable, seguro y sostenible. Y que cobres justamente por hacerlo.
              </p>
            </div>
          </div>

          <div className={styles.card}>
            <img
              src="https://i.ibb.co/FDzDkSf/MAQUINA.png"
              alt="Marque la diferencia"
              className={styles.cardImageSec}
            />
            <div className={styles.cardText}>
              <h3>Marque la diferencia</h3>
              <p>
                Involucrarse en Torchain le permite participar proactivamente en proyectos con un gran impacto social.
                Buscando tener un efecto positivo en millones de personas.
              </p>
            </div>
          </div>

          <div className={styles.card}>
            <img
              src="https://i.ibb.co/w4vHvrd/FAMILIA-JUAGANDO.png"
              alt="Encuentre su tribu"
              className={styles.cardImageThird}
            />
            <div className={styles.cardText}>
              <h3>Encuentre su tribu</h3>
              <p>
                Hay una tribu para todos. Encuentre y conéctese con personas afines para debatir, reflexionar y celebrar Torchain juntos.
                Únase para obtener consejos, ayuda y hablar sobre todo lo relacionado con las participaciones.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Nuevas Secciones */}
      <section className={styles.sectionsWrapper}>
        {/* Primera sección */}
        <section className={styles.parentEducationSection}>
          <div className={styles.leftContent}>
            <div className={styles.gradientBackground}>
              <div className={styles.textContent}>
                <h2 className={styles.sectionTitle}>Paso 1</h2>
                <h3 className={styles.sectionSubtitle}>Guías de<br />Torchain</h3>
                <p className={styles.descriptionThree}>
                El equipo de mentores del Torchain elaboro un plan, considerando meticulosamente cualquier fortaleza, debilidad y personalidad para ayudarte a potenciar tu salud, mentalidad y mejorar profesionalmente mientras generas dinero.
                <br />Desarrolle las habilidades mas importantes del s.XXI aprendiendo de algunos de los mejores expertos.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.rightContent}>
            <img
              src="https://i.ibb.co/K6RSzs7/CORRECCIONLABORATORIO-Mesa-de-trabajo-1.jpg"
              alt="Formación para padres"
              className={styles.educationImageDos}
            />
          </div>
        </section>

        {/* Segunda sección */}
        <section className={styles.parentEducationSectionSec}>
          <div className={styles.rightContent}>
            <img
              src="https://i.ibb.co/sRJjXYJ/TORCHAIN-LABORATORIO-3-COLOR-ARREGLADO.jpg"
              alt="Comunidad para padres"
              className={styles.educationImageDos}
            />
          </div>
          <div className={styles.leftContent}>
            <div className={styles.gradientBackground}>
              <div className={styles.textContent}>
                <h2 className={styles.sectionTitle}>Paso 2</h2>
                <h3 className={styles.sectionSubtitle}>Crea tu Hoja de Ruta</h3>
                <p className={styles.descriptionThree}>
                Tras un análisis exhaustivo del contenido, elaborá un plan personalizado, considerando meticulosamente tus fortalezas, debilidades, habilidades y oportunidades para ayudarte a cuidar y potenciar tu salud, mentalidad.
                </p>
                <p className={styles.descriptionThree}>
                Recuerda, el camino que eliges es responsabilidad exclusivamente tuya.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Tercera sección */}
        <section className={styles.parentEducationSection}>
          <div className={styles.leftContent}>
            <div className={styles.gradientBackground}>
              <div className={styles.textContent}>
                <h2 className={styles.sectionTitle}>Paso 3</h2>
                <h3 className={styles.sectionSubtitle}>Pruebe su Conocimiento</h3>
                <p className={styles.descriptionThree}>
                Descubra lo bien que entiende su profesión ¿Está listo para convertirse en un experto?<br />Después de recibir estos pasos a seguir, es crucial implementar lo aprendido y aprovechar las herramientas y recursos
                  proporcionados por Torchain para agilizar tu desarrollo y lograr generar un impacto social.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.rightContent}>
            <img
              src="https://i.ibb.co/YD5P5B2/asdff.jpg"
              alt="Eventos en vivo"
              className={styles.educationImageStep}
            />
          </div>
        </section>
      </section>
      <section className={styles.educationSection}>
        <div className={styles.educationContent}>
          <h2 className={styles.educationTitle}>El Campus creado para redefinir el mundo de la educación</h2>
          <p className={styles.educationDescription}>
            La educación de calidad siempre fue reservada a un pequeño grupo de personas, queremos abrir esta puerta a todas las personas.
            Podrás ayudar a crear un mejor ecosistema familiar para el crecimiento de las próximas generaciones.
          </p>
          {/* Enlace al formulario */}
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLScx7We-Vn-qbILta2zoIFUDYGGyF0x8APTPdopP7dj7l79egA/viewform?usp=dialog" // Cambia esto por la URL del formulario
            target="_blank" // Abre el formulario en una nueva pestaña
            rel="noopener noreferrer" // Mejora de seguridad
            className={styles.educationButton}
          >
            Aplicar Ahora
          </a>
        </div>
        <div className={styles.educationImageContainer}>
          <img
            src="https://i.ibb.co/gD99TGq/SEMILLA-ARREGLADO.png" // Cambia la URL a la imagen adecuada
            alt="Visual de educación"
            className={styles.educationImage}
          />
        </div>
      </section>
      <section className={styles.opportunitySection}>
        <div className={styles.leftImageContainer}>
          <img
            src="https://i.ibb.co/mG8NLNR/MANOS.png" // Cambia esta URL por la imagen deseada
            alt="Oportunidad única para aprender"
            className={styles.opportunityImage}
          />
        </div>
        <div className={styles.rightTextContainer}>
          <h2 className={styles.opportunityTitle}>
            Aprovecha esta oportunidad <br /> única para aprender y crecer
          </h2>
          <p className={styles.opportunityDescription}>
            Torchain ofrece más que simplemente un trabajo, te da acceso a educación exclusiva. Es una puerta de entrada para adquirir experiencia invaluable en tu profesión, crecimiento personal, métricas esenciales, modelos de pensamientos y mucho más.<br />
            Tu crecimiento y experiencia de aprendizaje serán significativos. ¡Dentro de un año, te sorprenderás de cuánto has absorbido y evolucionado!
          </p>
          {/* Enlace al formulario */}
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLScx7We-Vn-qbILta2zoIFUDYGGyF0x8APTPdopP7dj7l79egA/viewform?usp=dialog" // Cambia esto por la URL del formulario                  // Cambia esta URL por la del formulario
            target="_blank" // Abre en una nueva pestaña
            rel="noopener noreferrer" // Mejora de seguridad
            className={styles.applyButton}
          >
            Aplicar Ahora
          </a>
        </div>
      </section>
      {/* Nueva sección exclusiva con texto e imagen */}
      <footer className={styles.footer}>
        <div className={styles.logoContainer}>
          <img
            src="https://i.ibb.co/VtC0NV6/torchain-original-blanco.png" // Cambia por el logo correspondiente (blanco o negro)
            alt="Torchain Logo"
            className={styles.logo}
          />
        </div>
        <div className={styles.socialContainer}>
          <div className={styles.socialIcons}>
            <a
              href="https://www.instagram.com/torchain/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.socialLink}
            >
              <FaInstagram />
            </a>
            <a
              href="https://www.tiktok.com/@torchain_?lang=fr"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.socialLink}
            >
              <FaTiktok />
            </a>
            <a
              href="https://www.youtube.com/@torchain"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.socialLink}
            >
              <FaYoutube />
            </a>
          </div>
        </div>
        <div className={styles.contactContainer}>
          <p className={styles.contactTitle}>Contactos:</p>
          <a href="torchain.official@gmail.com" className={styles.email}>
          torchain.official@gmail.com
          </a>
        </div>
      </footer>
    </div>
  );
}
