import React from 'react';
import Header from './Header'
import styles from './Family.module.css';

import { FaTiktok, FaInstagram, FaYoutube } from 'react-icons/fa';

export default function Family() {
  return (
    <div className={styles.family}>
      <Header />
      <section className={styles.hero}>
        <img
          src="https://i.ibb.co/bHdFyq1/TORCHAIN-FAMILIA-FOGON-arreglado-page-0001.jpg"
          alt="Ilustración de un ecosistema de aprendizaje virtual con personas y un robot en un entorno forestal"
          className={styles.heroImage}
        />
        <div className={styles.heroContent}>
          <div className={styles.brand}>
            <img
              src="https://i.ibb.co/cvX2hdV/torchain-familia-fondo.jpg"
              alt="TORCHAIN"
              className={styles.brandLogo}
            />
          </div>
          <h1 className={styles.heroTitle}>
            El Ecosistema Virtual<br />
            para Grandes Familias
          </h1>
          <p className={styles.heroText}>
            Somos tu aliado estratégico en el progreso y la felicidad de tu hijo, cultivando las
            habilidades mas importantes del Siglo XXI
          </p>
        </div>
      </section>
      <section className={styles.videoSection}>
        <div className={styles.container}>
          <div className={styles.videoContainer}>
            <div className={styles.videoWrapper}>
              <img 
                src="https://img.youtube.com/vi/9LC-7T9j5QE/maxresdefault.jpg"
                alt="Un Proyecto De Todos y Para Todos - Torchain" 
                className={styles.videoThumbnail}
              />
              <a 
                href="https://youtu.be/9LC-7T9j5QE?si=IDClkOZgU6CJRvfT" 
                target="_blank" 
                rel="noopener noreferrer"
                className={styles.videoOverlay}
              >
                <div className={styles.playButton}>
                  <svg viewBox="0 0 24 24" className={styles.playIcon}>
                    <path fill="currentColor" d="M8 5v14l11-7z"/>
                  </svg>
                </div>
              </a>
            </div>
          </div>
          <div className={styles.contentContainer}>
            <h2 className={styles.greenTitle}>HAZ CRECER EL POTENCIAL Y LA FELCIDAD DE TU HIJO</h2>
            <h3 className={styles.mainTitle}>Te acompañamos en los retos educativos de hoy</h3>
            <p className={styles.description}>
              Nuestra propuesta de solución educativa integral se destaca por ofrecer innovación, transformación, acompañamiento, seguimiento y evaluación de procesos, brindando así un enfoque completo para mejorar el rendimiento y desarrollo de cada familia.
            </p>
            <p className={styles.descriptionOne}>
              Creemos en el potencial innato de cada niños y en su capacidad para convertirse en los verdaderos protagonistas de sus vidas.
            </p>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLScHn1aIUINiJE-WzhsQYMJqeHASBNkJW6GV4OxbxB852Q_IAQ/viewform?usp=dialog" // Cambia esta URL por la del formulario
              target="_blank" // Abre en una nueva pestaña
              rel="noopener noreferrer" // Mejora de seguridad
              className={styles.learnMoreButton}
            >
              Saber Mas
            </a>
          </div>
        </div>
      </section>
      <section className={styles.achievementSection}>
        <h2 className={styles.achievementTitle}>ASI LO LOGRAMOS</h2>
        <p className={styles.achievementText}>
          Te ayudamos a crear un ecosistema familiar efectivo que apoye el desarrollo académico, social y
          emocional de tus hijos, nuestra metodología nos permite acompañarte de principio a fin.
        </p>
      </section>
      {/* Primera sección */}
      <section className={styles.sectionsWrapper}>
        <section className={styles.parentEducationSection}>
          <div className={styles.leftContent}>
            <div className={styles.gradientBackground}>
              <div className={styles.textContent}>
                <h2 className={styles.sectionTitle}>Cultiva el Progreso de tu hijo</h2>
                <h3 className={styles.sectionSubtitle}>Formación para padres</h3>
                <p className={styles.descriptionThree}>
                  Entender los procesos de aprendizaje para lograr los objetivos compartidos.
                </p>
                <p className={styles.descriptionThree}>
                  Ofrecemos contenidos que dan respuesta a tus necesidades en el proceso de crianza, con la colaboración de expertos, asegurando el crecimiento de tus hijos con metas personalizadas.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.rightContent}>
            <img
              src="https://i.ibb.co/n80s3qV/TORCHAIN-BALCON-FAMILIA-COLOR-ARREGLADO.jpg"
              alt="Formación para padres"
              className={styles.educationImage}
            />
          </div>
        </section>
        {/* Segunda sección */}
        <section className={styles.parentEducationSectionSec}>
          <div className={styles.rightContent}>
            <img
              src="https://i.ibb.co/fnsDJgV/EVENTO-TORCHAIN-Mesa-de-trabajo-1.jpg" /* URL de la imagen */
              alt="Comunidad para padres"
              className={styles.educationImage}
            />
          </div>
          <div className={styles.leftContent}>
            <div className={styles.gradientBackground}>
              <div className={styles.textContent}>
                <h2 className={styles.sectionTitle}>Cultiva el progreso de tu hijo</h2>
                <h3 className={styles.sectionSubtitle}>Una verdadera comunidad para padres</h3>
                <p className={styles.descriptionThree}>
                  Un antídoto para las redes sociales. Nuestra comunidad es el mejor rincón de internet. Charlas honestas. Historias reales. Un verdadero apoyo.
                  Nunca más te sentirás solo o avergonzado.</p>
                <p className={styles.descriptionThree}>
                Así como acompañamiento a lo largo del año para tener constante retroalimentación.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* Tercera sección */}
        <section className={styles.parentEducationSection}>
          <div className={styles.leftContent}>
            <div className={styles.gradientBackground}>
              <div className={styles.textContent}>
                <h2 className={styles.sectionTitle}>Cultiva el progreso de tu hijo</h2>
                <h3 className={styles.sectionSubtitle}>Eventos en vivo con expertos</h3>
                <p className={styles.descriptionThree}>
                  Desde cómo hablar con tu hijo sobre sexo hasta cómo dejar que sus intereses guíenlos, los mejores expertos en su campo comparten conocimientos directamente con usted.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.rightContent}>
            <img
              src="https://i.ibb.co/YNvdDZR/Imagen-de-Whats-App-2024-12-05-a-las-20-25-49-464c8376.jpg" /* URL de la imagen */
              alt="Eventos en vivo"
              className={styles.educationImage}
            />
          </div>
        </section>
        {/* Cuarta sección */}
        <section className={styles.parentEducationSectionSec}>
          <div className={styles.leftContent}>
            <div className={styles.videoWrapperFour}>
              <img 
                src="https://img.youtube.com/vi/KkrfHfUr9dI/maxresdefault.jpg" /* Miniatura del video */
                alt="Un Proyecto De Todos y Para Todos - Torchain" 
                className={styles.videoThumbnail}
              />
              <a 
                href="https://www.youtube.com/watch?v=KkrfHfUr9dI" /* Enlace al video en YouTube */
                target="_blank" 
                rel="noopener noreferrer"
                className={styles.videoOverlay}
              >
                <div className={styles.playButton}>
                  <svg viewBox="0 0 24 24" className={styles.playIcon}>
                    <path fill="currentColor" d="M8 5v14l11-7z"/>
                  </svg>
                </div>
              </a>
            </div>
          </div>
          <div className={styles.rightContent}>
            <div className={styles.gradientBackground}>
              <div className={styles.textContent}>
                <h2 className={styles.sectionTitle}>CULTIVANDO - PROXIMAMENTE</h2>
                <h3 className={styles.sectionSubtitle}>Formación para tu hijo</h3>
                <p className={styles.descriptionThree}>
                  Estamos desarrollando tecnología educativa, trabajamos para subir el nivel de tu hijo, en la resolución de problemas.
                  <br />
                  Entrenamientos en vivo, con maestros que se enfocan en las habilidades más importantes del siglo XXI.
                </p>
              </div>
            </div>
          </div>
        </section>
      </section>
      {/* Nueva sección exclusiva con texto e imagen */}
      <footer className={styles.footer}>
        <div className={styles.logoContainer}>
          <img
            src="https://i.ibb.co/t3d41zf/torchain-original-negro.png" // Cambia por el logo correspondiente (blanco o negro)
            alt="Torchain Logo"
            className={styles.logo}
          />
        </div>
        <div className={styles.socialContainer}>
          <div className={styles.socialIcons}>
            <a
              href="https://www.instagram.com/torchain/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.socialLink}
            >
              <FaInstagram />
            </a>
            <a
              href="https://www.tiktok.com/@torchain_?lang=fr"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.socialLink}
            >
              <FaTiktok />
            </a>
            <a
              href="https://www.youtube.com/@torchain"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.socialLink}
            >
              <FaYoutube />
            </a>
          </div>
        </div>
        <div className={styles.contactContainer}>
          <p className={styles.contactTitle}>Contactos:</p>
          <a href="torchain.official@gmail.com" className={styles.email}>
          torchain.official@gmail.com
          </a>
        </div>
      </footer>
    </div>
  );
}

