import React, { useState, useEffect } from 'react';
import styles from './Header.module.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Estado del menú hamburguesa
  const location = useLocation();
  const navigate = useNavigate();

  // Efecto para desplazar hacia la parte superior en cada cambio de ruta
  useEffect(() => {
    window.scrollTo(0, 0); // Cambia directamente al principio de la página
  }, [location.pathname]);

  // Determina si está en Campus
  const isCampus = location.pathname.includes('campus');
  const headerStyle = isCampus ? styles.headerBlack : styles.headerWhite;

  // Cambia el logo según la ruta
  const logoSrc = isCampus
    ? "https://i.ibb.co/4R3FyWF/torchain-original-blanco.png" // Logo blanco
    : "https://i.ibb.co/t3d41zf/torchain-original-negro.png"; // Logo negro

  // Función para manejar el clic en el logo
  const handleLogoClick = () => {
    navigate('/'); // Navega a la página raíz (Familia)
    window.scrollTo(0, 0); // Mueve la vista al principio de la página
  };

  // Función para alternar el menú hamburguesa
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className={`${styles.header} ${headerStyle}`}>
      <div className={styles.headerContent}>
        {/* Logo con función de clic */}
        <img
          src={logoSrc}
          alt="TORCHAIN"
          className={styles.logo}
          onClick={handleLogoClick}
          style={{ cursor: 'pointer' }} // Cambia el cursor para indicar que es clickeable
        />

        {/* Menú hamburguesa solo visible en mobile */}
        <div className={styles.hamburger} onClick={toggleMenu}>
          ☰
        </div>

        {/* Menú desplegable en mobile */}
        {isMenuOpen && (
          <div className={styles.mobileMenu}>
            <Link to="/" className={styles.navLink} onClick={toggleMenu}>
              Familia
            </Link>
            <Link to="/campus" className={styles.navLink} onClick={toggleMenu}>
              Campus
            </Link>
          </div>
        )}

        {/* Menú estándar solo visible en pantallas grandes */}
        <nav className={styles.nav}>
          <Link to="/" className={styles.navLink}>
            Familia
          </Link>
          <Link to="/campus" className={styles.navLink}>
            Campus
          </Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;
